import axios from "../config/axios";

import { setting } from "src/redux/settings/setting.action";
import { handleResp } from "../config/axios";
export const getSiteSetting = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminApi/getSiteSetting`,
    });

    setting(respData.data.result,dispatch);
    
    return {
      status: true,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: false,
    };
  }
};

export const updateMarketTrend = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/adminApi/updateMarketTrend`,
      data,
    });

    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: false,
      message: err.response.data.message
    };
  }
};

export const updateSiteDetail = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/adminApi/updateSiteDetails`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.error
    };
  }
};

export const updateUsrDash = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/adminApi/updateUsrDash`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.error
    };
  }
};

export const getPairDropdown = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminApi/getPairDropdown`
    });
    return {
      status: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: false,
    };
  }
};
export const updateMailConfig = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminApi/updateMailConfig`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.error
    };
  }
};

export const getMailConfig = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminApi/getMailConfig`,
      data,
    });
    return {
      status: true,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: false,
    };
  }
};

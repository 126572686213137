// import packages
import axios from 'axios'

// import lib
import config from './index'
import { getAuthToken } from '../lib/localStorage'
import { logout } from "src/redux/auth/auth.action";
import { Navigate } from "react-router-dom";
import store from "../redux/store"
axios.defaults.baseURL = config.API_URL
axios.defaults.headers.common['Authorization'] = getAuthToken()

export const setAuthorization = (token) => {
  axios.defaults.headers.common['Authorization'] = token
}
export const handleResp = (respData, type = 'success', doc) => {
  try {
    if (doc === true && type == 'success' && respData && respData.data) {
      return { data: respData.data }
    }
    if (type == 'success' && respData && respData.data) {
      return respData.data
    } else if (type == 'error' && respData && respData.response && respData.response.data) {
      if (respData &&
        respData.response &&
        respData.response.data && respData.response.status == 401) {
        localStorage.removeItem('admin_token')
        // Navigate('/login')
        logout({}, store.dispatch)
        return respData.response.data;
      }
      return respData.response.data
    } else {
      return {
        success: false,
        message: 'Something went wrong',
      }
    }
  } catch (err) {
    console.log("handleResphandleResp",err)
    return {
      success: false,
      message: 'Something went wrong',
    }
  }
}
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('admin_token')
    logout({}, store.dispatch)
    setTimeout(() => {
      window.location.href = '/login'
    }, 500);
    // window.location.href = '/login'
  }
  return Promise.reject(error);
});

export default axios

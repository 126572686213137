import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilMoney,
  cilList,
  cilSpeech,
  cilSettings,
  cilEnvelopeClosed,
  cilStar,
  cilUserFemale,
  cilTransfer,
  cilDataTransferDown,
  cilDataTransferUp,
  cilChartLine,
  cilLoopCircular,
  cilWallet,
  cilGraph,
  cilVerticalAlignCenter,
  cibServerFault,
  cilUserPlus,
  cilBook,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "User Management",
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Users",
        to: "/users",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "System Locked User",
        to: "/sytem-locked-User",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },

    ],
  },
  {
    component: CNavGroup,
    name: "Deposit Management",
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Deposit Setting",
        to: "/deposit-setting",
        icon: <CIcon icon={cilDataTransferDown} customClassName="nav-icon" />,
      }, ,
      {
        component: CNavItem,
        name: "Deposit",
        to: "/deposit-list",
        icon: <CIcon icon={cilDataTransferDown} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Admin Deposit move",
        to: "/admin-deposit-list",
        icon: <CIcon icon={cilDataTransferDown} customClassName="nav-icon" />,
      },

    ],
  },
  {
    component: CNavItem,
    name: "Withdraw",
    to: "/withdraw-list",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Currency",
    to: "/currency",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: "Price CNV",
    to: "/price-conversion",
    icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: "Contact Us",
    to: "/contact-us",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Templates & CMS",
    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Email Templates",
        to: "/email-templates",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "CMS Pages",
        to: "/cms",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup,
    name: "FAQ",
    icon: <CIcon icon={cilSpeech} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Category",
        to: "/faq-category",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "FAQ",
        to: "/faq",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Support",
    icon: <CIcon icon={cilSpeech} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Category",
        to: "/support-category",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Tickets",
        to: "/support-tickets",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },


  {
    component: CNavItem,
    name: "Site Settings",
    to: "/site-settings",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "PassBook",
    to: "/passbook",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },

];

export default _nav;
